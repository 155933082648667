.business{
    .business{
        &-banner{
            padding: 3rem 1.5rem;
            background:transparent;
        overflow: hidden;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: linear-gradient(98.81deg, 
            rgba($color:#ffbc36,$alpha:1) -0.82%,
            rgba($color:#e08f00,$alpha:1) 101.53%);
            z-index: -2;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: url('../../assets/watermark.svg') no-repeat;
            background-size: cover;
            filter: brightness(0) invert(100%);
            opacity: 0.2;
            z-index: -1;
        }
            &-image{
                width: 90%;
                margin: 24px auto;
                img{
                    width: 100%;
                }
            }
            &-content{
                padding: 0 2rem;
               h1{
                   color: #fff;
                   font-size: 40px;
                text-align: center;
               }
               p{
                color: #616161;
                font-size: 32px;
                font-weight: 600;
                text-align: center;
               }
            }
        }
        &-work{
            h3{
                margin-bottom: 3rem;
                font-size: 28px;
                background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            padding: 5rem 1.5rem;
            background:  linear-gradient(98.81deg, rgba($color:#ffbc36,$alpha:0.2) -0.82%, rgba($color:#e08f00,$alpha:0.2) 101.53%);
            display: flex;
            flex-direction: column;
            justify-content: center;align-items: center;
            h4{
                margin-top: 20px;
                margin-bottom: 8px;
            }
            p{
                max-width: 280px;
                text-align: center;
                font-weight: 400;
            }
            .business-card{
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;align-items: center;
            }
        }

        &-feature-1,&-feature-2{
            padding: 3rem 1.5rem;
            background:#f8f8f8;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 280px;
            }

            h4{
                font-size: 20px;
                margin-bottom: 20px;
                margin-top: 20px;

            }

            p{
                max-width: 85%;
                text-align: center;
            }

            button{
                background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                border-radius: 15px;
                padding: 0.75rem 1rem;
                color: white;
                font-weight: 700;
                border: none;
                margin-top: 15px;
                width: 50%;
            }

            .content{
                display: flex;
                justify-content: center;align-items: center;
                flex-direction: column;

            }

    
           
           
        }

        &-feature-1{
            padding-bottom: 0;
        }

        &-form{
            padding: 3rem 1.5rem;
        background:transparent;
        overflow: hidden;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: linear-gradient(98.81deg, 
            rgba($color:#ffbc36,$alpha:1) -0.82%,
            rgba($color:#e08f00,$alpha:1) 101.53%);
            z-index: -2;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: url('../../assets/watermark.svg') no-repeat;
            background-size: cover;
            filter: brightness(0) invert(100%);
            opacity: 0.2;
            z-index: -1;
        }

        .form-container{
            background: rgba(255,255,255,0.75);
            padding: 2rem 1.25rem;
            border-radius: 8px;
            h3{
                text-align: center;
                font-size: 24px;
                margin-bottom: 1.5rem;
            }
            p{
                text-align: center;
                margin-bottom: 0.25rem;
                
            }

            input{
                width: 100%;
                background-color: #fff;
                padding: 0.5rem 1.5rem;
                border-radius: 8px;
                border: 1px solid #e08f00;
                font-size: 1rem;
                &::placeholder{
                    color: #ddd;
                }
                &:first-child{
                    margin-top: 1rem;
                }
                margin-bottom: 1rem;
            }

            button{
                background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                border-radius: 15px;
                padding: 0.75rem 1rem;
                color: white;
                font-weight: 700;
                border: none;
                margin-top: 15px;
                width: 50%;
                margin: 0 auto;
                display: block;
            }
        }
        }

        &-faq{
            padding: 3rem 1.5rem;
            background:#f8f8f8;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            h3{
                margin-bottom: 3rem;
                font-size: 28px;   
            }

            .accordian{
                border-bottom: 1px solid #ddd;
                width: 100%;
                padding: 0.875rem 0rem;
                &-tab{
                    display: flex;
                    justify-content: space-between;
                    img{
                        width: 24px;
                    }
                    cursor: pointer;
                }
                &-content{
                    max-height: 0px;
                    transition: all 250ms linear;
                    overflow: hidden;
                }
                &.active{
                    .accordian-content{
                        max-height: fit-content;
                        padding-top: 1.25rem;
                    }
                }
                
            }
        }
    }

    #snackbar {
        visibility: hidden; 
        min-width: 250px; 
        margin-left: -125px; 
        background-color: #333; 
        color: #fff; 
        text-align: center; 
        border-radius: 2px; 
        padding: 16px;
        position: fixed;  
        z-index: 1; 
        left: 50%; 
        bottom: 30px; 
    }
    
    .show {
        visibility: visible !important; 
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
    
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
    
    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
}