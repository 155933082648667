*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  margin: 0;
  position: relative;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  background: #fff;
  #root{
    max-width: 540px;
    width: 100%;
    margin:0 auto;
    overflow-x: hidden;
  }
}