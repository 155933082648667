.homepage{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 2rem;
    min-height: 100vh;

    &::before{
        content: '';
        position: absolute;
        display: block;
        background: url(../../assets/watermark.svg) no-repeat;
        background-position: top;
        background-size: cover;
        height: 40vh;
        top: 0;
        left: 0;
        right: 0;
        z-index: -2;
    }

    &::after{
        content: '';
        position: absolute;
        display: block;
        // width: 100%;
        background: linear-gradient(rgba(255,255,255,0), 10%, rgba(255,255,255,1));
        height: 40vh;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    
    .logo{
        margin-top: 5rem;
        display: flex;
        justify-content: center;align-items: center;
        img{
            width: 280px;
        }
    }

    .tabs{
        margin-top: 2rem;
        display: flex;
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        .tab{
            flex: 50%;
            padding: 0.75rem 1rem;
            p{
                font-weight: 700;
                text-align: center;
            }
            &.active{
                border-bottom: 3px solid #ffbc36;  
            }
        }
    }

    .scan{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .scan-image{
            width: 200px;
            margin: 2rem 0;
            img{
                width: 100%;
            }
        }

        .scan-button{
            background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
            border-radius: 15px;
            padding: 0.75rem 1rem;
            color: white;
            font-weight: 700;
            margin-bottom: 5rem;
        }
    }

    .takeaway{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .filter-takeaway{
            display: flex;
            margin-top: 2rem;
            padding: 0 1rem;
            .location{
                flex: 50%;
                position: relative;
                
                select{
                    display: block;
                    width: 100%;
                    background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                    color: white;
                    border-radius: 15px;
                    padding: 0.75rem 1rem;
                    font-weight: 700;
                    font-size: 1rem;
                    border: none;
                    height: 44px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                }
                &::after{
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0.75rem;
                    background-image: url("../../assets/location.png");
                    filter: invert(1);
                    background-repeat: no-repeat;
                    background-size: cover;
                    z-index: 10;
                }
                margin-right: 0.5rem;
            }
            .search{
                margin-left: 0.5rem;
                flex: 50%;
                input{
                    width: 100%;
                    background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                    color: white;
                    border-radius: 15px;
                    padding: 0.75rem 1rem;
                    font-size: 1rem;
                    font-weight: 700;
                    border: none;
                    height: 44px;
                    &::placeholder{
                        color: #fff;
                        font-weight: 700;
                        font-size: 1rem;

                    }
                }
            }
        }

        .restaurants{
            display: flex;
            flex-wrap: wrap;
            padding: 2rem 1rem;
            width: 100%;
            .restaurant{
                box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .restaurant-image{
                    display: flex;justify-content: center;

                    img{
                        width:100px;
                        filter: grayscale(1);
                    }
                }
               flex: calc(50% - 10px);
               width: calc(50% - 10px);
               max-width:calc(50% - 10px) ;
               margin-bottom: 20px;
               border-radius: 15px;
               padding: 1rem 1.5rem;
               margin-right: 20px;
               &:nth-child(even){
                   margin-right: 0px;
               }
               p{
                   font-weight: 700;
                   text-align: center;
               }
            }
        }
    }
}