#root{
    .la-costa-watermark{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            display: block;
            background: url(./assets/watermark.svg) no-repeat;
            background-position: top;
            background-size: cover;
            height: 40vh;
            top: 0;
            left: 0;
            right: 0;
            z-index: -2;
        }
    
        &::after{
            content: '';
            position: absolute;
            display: block;
            // width: 100%;
            background: linear-gradient(rgba(255,255,255,0), 10%, rgba(255,255,255,1));
            height: 40vh;
            top: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }
    }

    .la-costa-fixed-watermark{
        position: fixed;
        top: 0;
        left: 50%;
        right: 0;
        height: 100vh;
        z-index: -10;
        max-width: 540px;
        transform: translateX(-50%);
        width: 100%;

        &::before{
            content: '';
            position: absolute;
            display: block;
            background: url(./assets/watermark.svg) no-repeat;
            background-position: top;
            background-size: cover;
            height: 40vh;
            top: 0;
            left: 0;
            right: 0;
            z-index: -2;
        }
    
        &::after{
            content: '';
            position: absolute;
            display: block;
            // width: 100%;
            background: linear-gradient(rgba(255,255,255,0), 10%, rgba(255,255,255,1));
            height: 40vh;
            top: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
    }

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
}