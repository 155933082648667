.Confirm{
    min-height: 100vh;
    display: flex;
    justify-content: center;align-items: center;
    .confirm-content{
        display: flex;justify-content: center;align-items: center;
        flex-direction: column;
        .heading{
                font-weight: 700;
                font-size: 1.5rem;
                background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block; 
                margin-top: 1.5rem; 
                margin-bottom: 1rem;
        }
    }

    .back-to-menu{
        position: fixed;
        bottom: 2rem;
        max-width: 50%;
        background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
        border-radius: 15px;
        font-weight: 700;
        color: #fff;
        padding: 1.125rem 2.625rem;

    }

}