.Orders{
    padding: 2rem 1rem;
    padding-bottom: 80px;
    .rdrCalendarWrapper{
      width: 100%;
      overflow: hidden;
      .rdrMonth{
        width: 100%;
      }
      .rdrMonthAndYearWrapper,.rdrMonth{
        display: block;
        transition: all 250ms ease-in-out;
        overflow: hidden;
      }
      .rdrMonthAndYearWrapper{
          display: flex;
      }
      &:not(.active){
        .rdrMonthAndYearWrapper,.rdrMonth{
          display: none;
          transition: all 250ms ease-in-out ;
          overflow: hidden;
        }
      }

    }
    
    .heading{
      p{
        font-size: 1.875rem;
        font-weight: 700;
      }
    }
    .order-filters{
      position: sticky;
      top: 1rem;
      z-index: 10;

      &::after{
          position: absolute;
          content: '';
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          width: 25px;
          height: 25px;
          background: url('../../../assets/search.svg') no-repeat;
          z-index: 11;
          background-size: cover;
      }
      input{
          width: 100%;
          background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
          color: white;
          border-radius: 15px;
          padding: 0.75rem 1rem;
          font-size: 1rem;
          font-weight: 700;
          border: none;
          height: 44px;
          &::placeholder{
              color: #fff;
              font-weight: 400;
              font-size: 1rem;               
              font-style: normal;
              font-weight: 400;
font-size: 12px;
line-height: 14px;
          }
      }

      &.sticked{
          &::before{
              content: '';
              position: absolute;
              top: -1rem;
              right: -1.5rem;
              bottom: -1rem;
              left: -1.5rem;

              background: linear-gradient(rgba(255,255,255,1), 50%, rgba(255,255,255,0));

              z-index: -1;
          }
      }
    }

    .order-lists{
      display: flex;
      flex-direction: column;
      margin-top: 3rem;

      .orderId{
        font-size: 0.875rem;
        margin-bottom: 1rem;
        font-weight: 600;
        opacity: 0.75;
      }

      .order-list{
        display: flex;
        flex-direction: column;
        box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
        padding: 1rem 1.5rem;
        border-radius: 15px;
        background-color: #fff;
        margin-bottom: 1rem;
        .product-detail{
          display: flex;
          flex-direction: column;
          border-bottom: 1px dotted #707070;
          .product-row{
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            p{
              font-size: 14px;
              color: rgba(0,0,0,0.7);
            }
            .product-icon{
              display: inline-flex;
              margin-right: 0.5rem;
              img{
                width: 14px;
              }
            }
            .product-flex{
              display: flex;
            }
          }
        }
        .bill-details{
          padding-top: 1rem;
          .price{
            font-size: 14px;
            color: rgba(0,0,0,0.7);
            display: flex;justify-content: space-between;
            margin-bottom: 0.35rem;
          }
          .total{
            display: flex;
            justify-content: space-between;
            border-top: 1px solid rgba($color:#707070,$alpha:0.1);
            padding-top:0.35rem;
            p{
              font-size: 14px;
              color: rgba(0,0,0,0.7);
            }
            .bill{
               color: #000;
               font-weight: 700;
               span{
                 margin-left: 1rem;
               }
            }
            .merchant{
              opacity: 0.75;
            }
          }
        }
      }
    }

    


}