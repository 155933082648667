.Cart{
    min-height: 100vh;
    padding:2rem 1rem;
    position: relative;

    .cart-heading{
        font-size: 25px;
        line-height: 32px;
        font-weight: 800;
        color: #3B3B3B;
        margin-bottom: 1.5rem;
    }

    .cart-lists{
        .cart-list{
            display: flex;
            background: #e08f00;
            box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
            border-radius: 22px;
            margin-bottom: 20px;
            
            .item-detail{
                background-color: #fff;
                border-top-right-radius: 22px;
                border-bottom-right-radius: 22px;
                border-top-left-radius: 22px;
                border-bottom-left-radius: 22px;

                padding: 1rem;
                flex: 85%;
                display: flex;
                justify-content: space-between;
                p{
                    &.name{
                        font-weight: 600;
                    }

                    &.category{
                        color: rgba($color:#3B3B3B,$alpha:0.3);
                    }

                    &.item-price{
                        font-weight: 600;
                        background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        display: inline-block;
                    }
                }
                .item-update{
                    display: flex;
                    align-items: center;
                    .item-minus{
                        background: linear-gradient(98.81deg, 
                        rgba($color:#ffbc36,$alpha:0.1) -0.82%,
                        rgba($color:#e08f00,$alpha:0.1) 101.53%);
                        color: #e08f00;
                        font-weight: 800;
                        width: 25px;height: 25px;
                        mix-blend-mode: normal;
                        border: 0px;
                        border-radius: 8px;
                        margin-right: 16px;
                        font-style: 16px;
                        line-height: 1;
                    }
                    .item-plus{
                        background: linear-gradient(98.81deg, 
                        rgba($color:#ffbc36,$alpha:1) -0.82%,
                        rgba($color:#e08f00,$alpha:1) 101.53%);
                        color: #fff;
                        font-weight: 800;
                        width: 25px;height: 25px;
                        mix-blend-mode: normal;
                        border: 0px;
                        border-radius: 8px;
                        margin-left: 16px;
                        font-style: 16px;
                        line-height: 1;
                    }
                }

            }
            .item-delete{
                flex: 15%;
                display: flex;align-items: center;
                justify-content: center;
                button{
                    background: transparent;
                    border: 0px;
                }
            }
        }
    }

    .back-to-menu{
        background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
        color: #fff;
        font-size: 1rem;
        width: calc(50%);
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: 600;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 15px;
        bottom: 1rem;
        position: absolute;
    }

    .place-order{
        padding: 1rem 0.75rem;
        position: fixed;
        bottom: 1rem;
        border-radius: 22px;
        background:transparent;
        width: calc(100% - 2rem);
        overflow: hidden;
        z-index: 10;

        &::after{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: linear-gradient(98.81deg, 
            rgba($color:#ffbc36,$alpha:1) -0.82%,
            rgba($color:#e08f00,$alpha:1) 101.53%);
            z-index: -2;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: url('../../assets/watermark.svg') no-repeat;
            background-size: cover;
            filter: brightness(0) invert(100%);
            opacity: 0.2;
            z-index: -1;
        }

        &-content{
           p{

               display: flex;
               justify-content: space-between;
               color: #fff;
               font-size: 0.875rem;
               &:first-child{
                   font-size: 1.125rem;
               }
               &.total{
                   font-weight: 800;
                   margin-top: 1rem;
                   margin-bottom: 1rem;
                   font-size: 1rem;
               }
           }
        }

        &-button{
            border: none;
            background: #fff;
            width: 100%;
            text-align: center;
            font-weight: 600;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-radius: 15px;
            cursor: pointer;
            p{
            
                font-weight: 700;
                font-size: 0.875rem;
                background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
            }
        }
    }
}


@media screen and (min-width:541px) {
    .Cart{
        .place-order{
            max-width: 540px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}