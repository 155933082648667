.Contact{
    padding: 1.5rem 0.75rem;
    min-height: calc(100vh - 238.5px);
    h1{
        margin-bottom: 2rem;
    }
    .contact-info {
        display: flex;
        align-items: flex-start;
        
        margin-bottom: 0.75rem;
    }

    .contact-info i {
        font-size: 0.875rem;
        margin-right: 10px;
    }

    .contact-info span {
        font-size: 1rem;
    }

    .fa-phone{
        transform: rotate(90deg);
    }
}