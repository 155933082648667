.Menu{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3.75rem 1.5rem;
    padding-bottom: 10rem;
    // padding-top: 4rem;
    background: transparent;

    .restaurant-name{
        font-style: normal;
font-weight: 700;
font-size: 28px;
margin-bottom: 0.25rem;
color: #000;
width: 70%;
    }

    .signout{
        position: absolute;
        top: 1rem;right: 1rem;
        z-index: 5;
        img{
            filter: invert(89%) sepia(0%) saturate(0%) hue-rotate(207deg) brightness(89%) contrast(92%);
        }
    }

    .orders-button{
        position: absolute;
        top: 3.5rem;right: 1rem;
        z-index: 5;
        width: 30px;
        height: 30px;
        img{
            width: 100%;
            filter:grayscale(1)
        }
    }

    .restaurant-table{
        margin-bottom: 1rem;
        font-size: 0.875rem;
        line-height: 0.875rem;
        opacity: 0.5;
        display: flex;align-items: center;
        img{
            margin-right: 0.5rem;
            width: 1.5rem;
        }
    }

    .search-menu{
        position: sticky;
        top: 1rem;
        z-index: 10;

        &::after{
            position: absolute;
            content: '';
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            background: url('../../assets/search.svg') no-repeat;
            z-index: 11;
            background-size: cover;
        }
        input{
            width: 100%;
            background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
            color: white;
            border-radius: 15px;
            padding: 0.75rem 1rem;
            font-size: 1rem;
            font-weight: 700;
            border: none;
            height: 44px;
            &::placeholder{
                color: #fff;
                font-weight: 400;
                font-size: 1rem;               
                font-style: normal;
                font-weight: 400;
font-size: 12px;
line-height: 14px;
            }
        }

        &.sticked{
            &::before{
                content: '';
                position: absolute;
                top: -1rem;
                right: -1.5rem;
                bottom: -1rem;
                left: -1.5rem;
 
                background: linear-gradient(rgba(255,255,255,1), 50%, rgba(255,255,255,0));

                z-index: -1;
            }
        }
    }

    .menu-lists{
        p.category-name{
            font-style: normal;
        font-weight: 700;
    font-size: 18px;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
       img{
            transition: transform 300ms ease-in-out;
            width: 35px;
            transform: rotate(0deg);
        }
    }
    &.active{
        span{
            img{
               transform: rotate(180deg);
            // width: 35px;
        }
    }
   }
        }

        p.subcategory{
            font-style: normal;
font-weight: 400;
font-size: 16px;
   margin-top: 1rem;
   color: rgba(0,0,0,1);
   opacity: 0.5;
   display: flex;
   justify-content: space-between;
   align-items: center;
   span{
      img{
           transition: transform 300ms ease-in-out;
           width: 35px;
           transform: rotate(0deg);
        }
    }
    &.active{
        span{
            img{
              transform: rotate(180deg);
           // width: 35px;
       }
   }}

        }

        .sub-category-items,.category-items{
            max-height: 0;
            overflow: hidden;
            transition: max-height 300ms ease-in-out;
        }
        
        .sub-category-items.active,.category-items.active{
            max-height: fit-content;
            transition: max-height 300ms ease-in-out;
        }

        .menu-item{
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .isVeg{
                width: 15px;
                margin-bottom: 0px;
                img{
                    width: 100%;
                }
            } 
            .name{
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 5px;
            }
            .menu-price{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                margin-bottom: 10px;
                opacity: 0.5;
            }

            .desc{
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 14px;
                max-width: 65%;
            }
        }

        .add-to-cart{
            button{
                padding: 0.5rem 1rem;
                border-radius: 1rem;
                border: 1px solid #e08f00;
                background: #fff;
                font-size: 0.75rem;
            }
            .updateCart{
                padding: 0.5rem;

                display: flex;
                align-items: center;
                p{
                    font-size: 12px;
                    margin-left: 8px;
                    margin-right: 8px;
                    margin-bottom: 0;
                }
                span{
                    padding:0 8px;
                    display: inline;
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 0.75rem;
                }
            }
        }
    }

    .bottom-buttons{
        position: fixed;
        bottom: 2rem;
        right: 1rem;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        z-index: 11;

        .float-menu{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #000;
            transition: all 300ms ease-in-out;
            img{
                width: 100%;
            }
    
            &.active{
              width: 55vw;
              max-width:540px;
              height: 30vh;
              border-radius: 1rem;
              &::after{
                  content: '';
                  position: fixed;
                  top: 0;left: 0;bottom: 0;right: 0;
                  background: rgba(0,0,0,0.3);
                  z-index: -1;
              }
              padding: 1.5rem;
              overflow-x: hidden;
              overflow-y: auto;
              .float-menu-lists{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  .float-menu-list{
                      width: 100%;
                      p{
                          color: #fff;
                          font-weight: 700;
                          font-size: 1rem;
                          padding: 0.5rem 1rem;
                          width: 100%;
                          text-align: center;
                          opacity: 0.7;
                          transition: all 300ms ease-in-out;
                          &:hover,&:visited,&:active{
                              opacity: 1;
                          }
                      }
                  }
              }
            }

        }

        .cart-button{
           width: calc(100% - 2rem);
            margin-top: 1rem;
            margin-left: 1rem;
            background: linear-gradient(98.81deg, #a0c73a -0.82%, #7bab47 101.53%);
            color: white;
            border-radius: 15px;
            padding: 0.75rem 1rem;
            p{
                color: white;
                &:last-child{

                    font-weight: 700;
                }
            }
            display: flex;
            justify-content: space-between;
            position: relative;

            &::after{
                content: '';
                position: absolute;
                top: -1rem;
                left: -1rem;
                right: -1rem;
                bottom: -2rem;
                background: linear-gradient(rgba(255,255,255,0), 1%, rgba(255,255,255,1));
                z-index: -3;

            }
        }
    }

    .waiter-button{
        position: fixed;
        bottom: 2rem;
        left: 1rem;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        z-index: 13;
        border-radius: 50%;
        width: 50px;height: 50px;
        background: #000;
        img{
            filter: invert(1);
            width: 65%;
        }
    }
    





}

@media screen and (min-width:541px) {
    .Menu{
        .bottom-buttons{
            left: 50%;
            right: unset;
            transform: translateX(-50%);
            max-width: 540px;
            .cart-button{
                width: 100%;
            }
        }
    }
}