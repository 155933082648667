.home{
    padding: 3.75rem 1.875rem;
    background: transparent;
    .restaurant-name{
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 800;
        margin-bottom: 20px;
    }

    .tables{

        .heading{
            margin-bottom: 20px;
            font-size: 1rem;
            font-weight: 700;
            color: #09051C;
        }


        .table-items{
            display: flex;
            flex-wrap: wrap;

            .table-item{
                position: relative;
                box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
                // background: #fff;
                img{
                    width: 80%;
                    margin: 0 auto;
                    display: block;
                }
               flex: calc(50% - 10px);
               max-width: calc(50% - 10px);
               margin-bottom: 20px;
               border-radius: 15px;
               padding: 1rem 1.5rem;
               margin-right: 20px;
               &:nth-child(even){
                   margin-right: 0px;
               }
               p{
                   font-weight: 600;
                   color: rgba($color:#000,$alpha:0.3);
               
                   text-align: center;margin-top: 8px;
               }
               .waiter{
                position: absolute;
                border-radius: 50%;
                color: #fff;
                background: #7bab47;
                width: 20px;
                height: 20px;
                font-size: 12px;
                top: -10px;left: 20px;
                margin-top: 8px;
               }
               p.orders{
                    position: absolute;
                    border-radius: 50%;
                    color: #fff;
                    background: #e08f00;
                    width: 20px;
                    height: 20px;
                    font-size: 12px;
                    top: -10px;right: -10px;
               }
            }
        }
    }
}