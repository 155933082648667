.Shipping{
    padding: 2rem 0.75rem;
    min-height: calc(100vh - 238.5px);

    h3{
        margin-bottom: 1rem;
        margin-top: 2rem;

    }
    ul{
        list-style-position: outside;
        margin-left: 1.5rem;
        margin-bottom: 0.5rem;
    }
    p{
        margin-bottom: 0.5rem;
    }
    li{margin-bottom: 0.25rem;}
}