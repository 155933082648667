.Inventory{
    padding: 2rem 1rem;
    padding-bottom: 80px;
    .heading{
        p{
          font-size: 1.875rem;
          font-weight: 700;
        }
    }
    .float-menu{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #000;
        transition: all 300ms ease-in-out;
        display: flex;justify-content: center;align-items: center;
        p{
            color: #fff;
            font-weight: 700;
            // transform: scale(1.5,1.5);
            font-size: 2.95rem;
            display: inline;
            height: 50px;
            line-height: 1;
            vertical-align: middle;
        }
    }

    .bottom-buttons{
        position: fixed;
        bottom: 6.5rem;
        right: 1rem;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        z-index: 11;

        .float-menu{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #000;
            transition: all 300ms ease-in-out;
            display: flex;justify-content: center;align-items: center;
            p{
                color: #fff;
                font-weight: 700;
                // transform: scale(1.5,1.5);
                font-size: 2.95rem;
                display: inline;
                height: 50px;
                line-height: 1;
                vertical-align: middle;
            }
        }
    }
    
}