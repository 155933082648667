.Home{
    min-height: 100vh;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .business-card{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        img{
            width: 280px;
            
        }
        .business-button{
            margin-top: 2.5rem;
            background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
            border-radius: 15px;
            padding: 0.75rem 1rem;
            color: white;
            font-weight: 700;
            cursor: pointer;
        }
    }
    .food-card{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
        img{
            width: 280px;
            
        }
        .food-button{
            margin-top: 2.5rem;
            background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
            border-radius: 15px;
            padding: 0.75rem 1rem;
            color: white;
            font-weight: 700;
            cursor: pointer;
        }
    }
}