.table-orders{
    padding: 3rem 1.25rem;
    padding-bottom: 8rem;

    .heading{
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .table-orderslist{
        display: flex;
        flex-direction: column;
        justify-content: center;align-items: center;
        min-height: 60vh;
    }
    .table-order{
        padding: 1rem 0.75rem;
        border-radius: 22px;
        background:transparent;
        width: calc(100%);
        overflow: hidden;
        position: relative;
        margin-bottom: 1rem;

        &::after{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: linear-gradient(98.81deg, 
            rgba($color:#ffbc36,$alpha:1) -0.82%,
            rgba($color:#e08f00,$alpha:1) 101.53%);
            z-index: -2;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: url('../../../assets/watermark.svg') no-repeat;
            background-size: cover;
            filter: brightness(0) invert(100%);
            opacity: 0.2;
            z-index: -1;
        }

        &-content{
           p{
               display: flex;
               justify-content: space-between;
               color: #fff;
               font-size: 0.875rem;
               &.total{
                   font-weight: 800;
                   margin-top: 1rem;
                   margin-bottom: 1rem;
                   font-size: 1rem;
               }
           }
        }

        &-button{
            background: #fff;
            width: 100%;
            text-align: center;
            font-weight: 600;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-radius: 15px;
            margin-top: 0.5rem;
            p{
            
                font-weight: 700;
                font-size: 0.875rem;
                background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
            }
        }
    }


    .waiter-button{
        position: fixed;
        bottom: 6rem;
        left: 1rem;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        z-index: 13;
        border-radius: 50%;
        width: 50px;height: 50px;
        background: #7bab47;
        img{
            filter: invert(1);
            width: 65%;
        }
    }

    .clear-table{
        background: linear-gradient(98.81deg, 
        rgba($color:#ffbc36,$alpha:1) -0.82%,
        rgba($color:#e08f00,$alpha:1) 101.53%);
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 15px;
        text-align: center;
        position: fixed;
        bottom: 1rem;
        width: 90%;
        color: #fff;
        font-weight: 700;
        left: 50%;
        transform: translateX(-50%);
    }
}