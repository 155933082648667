
.Insights{
    padding: 2rem 1rem;
    padding-bottom: 80px;
    .heading{
        p{
          font-size: 1.875rem;
          font-weight: 700;
        }
    }

    .rdrCalendarWrapper{
        width: 100%;
        overflow: hidden;
        .rdrMonth{
          width: 100%;
        }
        .rdrMonthAndYearWrapper,.rdrMonth{
          display: block;
          transition: all 250ms ease-in-out;
          overflow: hidden;
        }
        .rdrMonthAndYearWrapper{
            display: flex;
        }
        &:not(.active){
          .rdrMonthAndYearWrapper,.rdrMonth{
            display: none;
            transition: all 250ms ease-in-out ;
            overflow: hidden;
          }
        }
  
      }
  
      
     
      
      .insights {
        margin: 2rem 0;
        display: flex;
        flex-direction: row;

        flex-wrap: wrap;
      }
      
      .insights .insight {
        padding: 1rem;

        flex: calc((100% / 2));
        max-width: calc((100% / 2) - 1rem);
        border-radius: 12px;
        background: linear-gradient(98.81deg, rgba(255, 188, 54, 0.3) -0.82%, rgba(224, 143, 0, 0.3) 101.53%);
        -webkit-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
        &:first-child{
            margin-right: 1rem;
        }
      }
      
      .insights .insight .heading {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-weight: 700;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }
      
      .insights .insight .heading .score {
        margin-left: auto;
      }
      
      .insights .insight .heading .score span {
        color: #44c790;
      }
      
      .insights .insight .heading span {
        font-size: 0.8rem;
      }
      
}