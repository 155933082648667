.edit-item{
    padding: 3.5rem 1.5rem;
    .heading{
        font-weight: 700;
        font-size: 1.5rem;

        margin-bottom: 1.5rem;
    }

    .sub-category-field{
        display: flex;
        align-items: center;justify-content: space-between;
    }

    .sub-category-field,.item-field{
        margin-bottom: 0.75rem;
        input,select{
            width: 80%;
            display: block;
            padding: 0.25rem 0.75rem;
            border: 1px solid #707070;
            border-radius: 5px;
            font-size: 1rem;
            &::placeholder{
                font-size: 1rem;
                color: rgba($color:#e08f00,$alpha:0.6);
            }
        }
        label{
            display: block;
            margin-bottom: 4px;
        }
        .delete{
            background: #e08f00;
            display: inline-block;
            padding: 0.25rem;
            img{
                width: 20px;
            }
        }
    }
    .sub-category-name p{
        display: block;
        margin-bottom: 4px;
    }
    .add-subCategory{
        display: inline-flex;align-items: center;
        background: #e08f00;
        padding: 0.5rem 1rem;
        color: #fff;
        font-weight: 700;
    }
    .save-button{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e08f00;
        padding: 0.5rem 1rem;
        color: #fff;
        font-weight: 700;
        text-align: center;
        margin-top: 3rem;
    }
    .delete-button{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e08f00;
        padding: 0.5rem 1rem;
        color: #fff;
        font-weight: 700;
        text-align: center;
        margin-top: 1rem;
    }
       
}