.Footer{
        padding: 1rem 0.75rem;
        background:transparent;
        overflow: hidden;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: linear-gradient(98.81deg, 
            rgba($color:#ffbc36,$alpha:1) -0.82%,
            rgba($color:#e08f00,$alpha:1) 101.53%);
            z-index: -2;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;bottom: 0;left: 0;right: 0;
            background: url('../../assets/watermark.svg') no-repeat;
            background-size: cover;
            filter: brightness(0) invert(100%);
            opacity: 0.2;
            z-index: -1;
        }

      
    .footer-header{
       color: #fff;
       font-weight: 800;
       font-size: 1.5rem;
       margin-bottom: 1rem;
       text-align: center;
    }

    .footer-links{
        display: flex;
        justify-content: center;align-items: center;
        flex-direction: column;
        margin-bottom: 1.5rem;
        .footer-link{
            flex-direction: column;
            color: #fff;
            font-weight: 500;
            margin-bottom: 0.75rem;
            cursor: pointer;
        }
    }
    .copyright{
        position: absolute;
        bottom: 0.5rem;right: 1rem;
        color: white;
        a{
            text-decoration: none;
            font-weight: 700;
            color: #fff;
        }
    }
}