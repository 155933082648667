.Restaurant-Menu{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3.75rem 1.5rem;
    padding-bottom: 10rem;
    // padding-top: 4rem;

    background: transparent;
    .restaurant-name{
        font-style: normal;
font-weight: 700;
font-size: 28px;
margin-bottom: 0.25rem;
color: #000;
width: 70%;
    }

    

    .restaurant-table{
        margin-bottom: 1rem;
        font-size: 0.875rem;
        line-height: 0.875rem;
        opacity: 0.5;
        display: flex;align-items: center;
        img{
            margin-right: 0.5rem;
            width: 1.5rem;
        }
    }

    .search-menu{
        position: sticky;
        top: 1rem;
        z-index: 10;

        &::after{
            position: absolute;
            content: '';
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            background: url('../../../assets/search.svg') no-repeat;
            z-index: 11;
            background-size: cover;
        }
        input{
            width: 100%;
            background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
            color: white;
            border-radius: 15px;
            padding: 0.75rem 1rem;
            font-size: 1rem;
            font-weight: 700;
            border: none;
            height: 44px;
            &::placeholder{
                color: #fff;
                font-weight: 400;
                font-size: 1rem;               
                font-style: normal;
                font-weight: 400;
font-size: 12px;
line-height: 14px;
            }
        }

        &.sticked{
            &::before{
                content: '';
                position: absolute;
                top: -1rem;
                right: -1.5rem;
                bottom: -1rem;
                left: -1.5rem;
 
                background: linear-gradient(rgba(255,255,255,1), 50%, rgba(255,255,255,0));

                z-index: -1;
            }
        }
    }

    .menu-lists{
        .category-name{
            p{
                font-style: normal;
            font-weight: 700;
        font-size: 18px;
        img{
            margin-left: 0.5rem;
            width: 20px;
        }
            }
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
       img{
            transition: transform 300ms ease-in-out;
            width: 35px;
            transform: rotate(0deg);
        }
    }
    &.active{
        span{
            img{
               transform: rotate(180deg);
            // width: 35px;
        }
    }
   }
        }

        p.subcategory{
            font-style: normal;
font-weight: 400;
font-size: 16px;
   margin-top: 1rem;
   color: rgba(0,0,0,1);
   opacity: 0.5;
   display: flex;
   justify-content: space-between;
   align-items: center;
   span{
      img{
           transition: transform 300ms ease-in-out;
           width: 35px;
           transform: rotate(0deg);
        }
    }
    &.active{
        span{
            img{
              transform: rotate(180deg);
           // width: 35px;
       }
   }}

        }

        .sub-category-items,.category-items{
            max-height: 0;
            overflow-y: auto;
            transition: max-height 300ms ease-in-out;
        }
        
        .sub-category-items.active,.category-items.active{
            max-height: fit-content;
            transition: max-height 300ms ease-in-out;
        }

        .menu-item{
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .isVeg{
                width: 15px;
                margin-bottom: 0px;
                img{
                    width: 100%;
                }
            } 
            .name{
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 5px;
            }
            .menu-price{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                margin-bottom: 10px;
                opacity: 0.5;
            }

            .desc{
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 14px;
            }
        }

        .add-to-cart{
            button{
                padding: 0.5rem 1rem;
                border-radius: 1rem;
                border: 1px solid #e08f00;
                background: #fff;
                font-size: 0.75rem;
            }
        }
    }

    .add-item-admin{
        padding: 0.5rem 1rem;
                border-radius: 1rem;
                border: 1px solid #e08f00;
                background: #fff;
                font-size: 1.15rem;
                margin-top: 15px;
                text-align: center;
    }

    .bottom-buttons{
        position: fixed;
        bottom: 6.5rem;
        right: 1rem;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        z-index: 11;

        .float-menu{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #000;
            transition: all 300ms ease-in-out;
            display: flex;justify-content: center;align-items: center;
            p{
                color: #fff;
                font-weight: 700;
                // transform: scale(1.5,1.5);
                font-size: 2.95rem;
                display: inline;
                height: 50px;
                line-height: 1;
                vertical-align: middle;
            }
            }
    }
    





}