.bottom-tab{
    bottom: 1rem;
    position: fixed;
    z-index: 15;
    width: calc(100% - 2rem);
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.1);
    border-radius: 22px;
    padding: 1rem 1.25rem;
    left: 1rem;
    right: 1rem;

    .tabs{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: auto;
        .tab{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2rem;
            text-decoration: none;
            color: #000;
            &:last-child{
                margin-right: 0;
            }

            img{
                width: 20px;
            }

            p{
                display: none;
            }

            &.active{
                p{
                   display: block;
                   margin-left: 0.875rem;
                   font-size: 12px;
                }
                padding: 0.5rem 2rem;
                // background: linear-gradient(98.81deg, #53E88B -0.82%, rgba($color:#15BE77, 101.53%);
                background: linear-gradient(98.81deg, 
                rgba($color:#ffbc36,$alpha:0.1) -0.82%,
                rgba($color:#e08f00,$alpha:0.1) 101.53%);
                border-radius: 12px;
            }
        }
    }
}