.Profile{

    .restaurant-image{
        height: 40vh;
        width: 100%;
        background-size: cover !important;
        img{
            width: 100%;
        }
    }
    .restaurant-details{
        transform: translateY(-10vh);
        display: flex;
    flex-direction: column;
    position: relative;
    padding: 3rem 1.5rem;
    background-color: white;
    border-top-left-radius: 3.5rem;
    border-top-right-radius: 3.5rem;
    overflow: hidden;
    height: 60vh;
    position: relative;

    .restaurant-stats{
        .restaurant-tag{
            border-radius: 35px;
            padding: 10px 14px;
            background: rgba($color: #e08f00, $alpha: .15);
            color: #e08f00;
            display: inline-block;
            p{
                font-size: 12px;
            }
        }
    }

    .name{
        margin-bottom: 20px;
        font-size: 27px;
        line-height: 36px;
        font-weight: 700;
    }

    .desc{
        font-size: 16px;   
        line-height: 20px;
    }

    .time{
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 25px;
        img{
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
    }


    &::before{
        content: '';
        position: absolute;
        display: block;
        background: url(../../../assets/watermark.svg) no-repeat;
        background-position: top;
        background-size: cover;
        height: 40vh;
        top: 0;
        left: 0;
        right: 0;
        z-index: -2;
    }

    &::after{
        content: '';
        position: absolute;
        display: block;
        // width: 100%;
        background: linear-gradient(rgba(255,255,255,0), 1%, rgba(255,255,255,1));
        height: 40vh;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .menu{
        margin-top: 3rem;
        padding: 0.75rem 1rem;
        color: white;
        text-align: center;
        font-weight: 700;
        background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
        box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
        border-radius: 15px;
    }  

    .logout{
        padding: 0.75rem 1rem;
        color: black;
        text-align: center;
        font-weight: 700;
        margin-top: 3rem;
        background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
        box-shadow: 11px 28px 50px rgba(20, 78, 90, 0.2);
        border-radius: 15px;
        width: 50%;
    }
    }
}