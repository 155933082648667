.login{
    padding: 3rem 1.25rem;

    .login-heading{
        text-align: center;
        font-size: 1.75rem;
        font-weight: 700;
        opacity: 0.7;
    }

    .mobile-number,.otp-number{
        margin-top: 2rem;
        margin-bottom: 2rem;
        input{
            width: 100%;
            border-radius: 5px;
            border: 1px solid #707070;
            padding: 0.75rem 1rem;

            font-size: 1rem;
            &::placeholder{
                color: #e08f00;
            }
        }
    }


    .logo{
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;align-items: center;
        img{
            width: 280px;
        }
    }

    .send-otp{
        background: linear-gradient(98.81deg, #ffbc36 -0.82%, #e08f00 101.53%);
        border-radius: 15px;
        padding: 0.75rem 1rem;
        color: white;
        font-weight: 700;
        text-align: center; 
    }
}